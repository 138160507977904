"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TranslationSDK = void 0;
const environment_1 = require("./environment");
class TranslationSDK {
    constructor(options = {}) {
        this.currentUrl = window.location.href;
        this.ignoredTags = ['script', 'style', 'iframe', 'noscript'];
        this.availableTranslations = {};
        this.replaceWorkerInstance = null;
        this.request = indexedDB.open('trinka-translation', 2);
        this.localTranslations = null;
        this.apiUrl = environment_1.environment.API_URL;
        this.httpClient = this.defaultHttpClient;
        this.lang = options.lang || 'en';
        this.observerDelay = options.observerDelay || 5000;
        this.mutationObserverEnabled = options.mutationObserverEnabled !== undefined ? options.mutationObserverEnabled : true;
        this.rootElement = options.rootElement || document.body;
        this.rootElement.setAttribute('trinka-language', this.lang);
        this.initialize();
    }
    async initialize() {
        this.localTranslations = null;
        if (this.mutationObserverEnabled) {
            this.registerDOMObserver();
            this.registerURLObserver();
        }
        try {
            await this.initializeDB();
            this.localTranslations = await this.getLatestTranslationRecord(this.lang);
            if (this.localTranslations && this.localTranslations.translationData) {
                this.availableTranslations = JSON.parse(this.localTranslations.translationData);
                this.translate();
            }
        }
        catch (error) {
            console.error('[ERROR] error during db operation ===> ', error);
        }
        try {
            await this.getAvailableTranslations();
            this.translate();
        }
        catch (error) {
            console.error('[ERROR] get translation api failed ===> ', error);
        }
        this.rootElement.addEventListener('click', () => {
            this.translate();
            setTimeout(() => {
                this.translate();
            }, 2000);
        });
    }
    initializeDB() {
        return new Promise((resolve, reject) => {
            this.request.onerror = (e) => {
                console.error('Error opening the database. Deleting and recreating...', e);
                this.deleteDatabase();
                reject(e);
            };
            this.request.onupgradeneeded = (e) => {
                this.db = e.target.result;
                console.log('Creating new database schema for version 2');
                if (this.db.objectStoreNames.contains('translation')) {
                    this.db.deleteObjectStore('translation');
                }
                let objectStore = this.db.createObjectStore('translation', { keyPath: 'id', autoIncrement: true });
                objectStore.createIndex('sha256sum', 'sha256sum', { unique: true });
                objectStore.createIndex('id', 'id', { unique: true });
                objectStore.createIndex('lang', 'lang', { unique: false });
                resolve(this.db);
            };
            this.request.onsuccess = (e) => {
                this.db = e.target.result;
                resolve(this.db);
            };
        });
    }
    deleteDatabase() {
        const deleteRequest = indexedDB.deleteDatabase('trinka-translation');
        deleteRequest.onsuccess = () => {
            console.log('Database deleted successfully');
        };
        deleteRequest.onerror = (e) => {
            console.error('Error deleting the database', e);
        };
    }
    insertTranslationRecord(sha256sum, translationData, lang) {
        let transaction = this.db.transaction(['translation'], 'readwrite');
        let objectStore = transaction.objectStore('translation');
        objectStore.add({ sha256sum, translationData, lang });
        transaction.oncomplete = () => {
            console.log('[DEBUG] Record inserted');
        };
        transaction.onerror = (error) => {
            console.error('[ERROR] Error inserting record ===>', error);
        };
    }
    deleteRecordBySha256(sha256sum) {
        let transaction = this.db.transaction(['translation'], 'readwrite');
        let objectStore = transaction.objectStore('translation');
        let index = objectStore.index('sha256sum');
        let request = index.openCursor(IDBKeyRange.only(sha256sum));
        request.onsuccess = (e) => {
            let cursor = e.target.result;
            if (cursor) {
                objectStore.delete(cursor.value.id);
                console.log(`[DEBUG] Deleted record with sha256sum: ${sha256sum}`);
            }
        };
        request.onerror = (error) => {
            console.error('[ERROR] Error deleting record ===> ', error);
        };
    }
    deleteRecordBySha256AndLang(sha256sum, lang) {
        let transaction = this.db.transaction(['translation'], 'readwrite');
        let objectStore = transaction.objectStore('translation');
        let index = objectStore.index('sha256sum');
        let request = index.openCursor(IDBKeyRange.only(sha256sum));
        request.onsuccess = (e) => {
            let cursor = e.target.result;
            if (cursor && cursor.value.lang === lang) {
                objectStore.delete(cursor.value.id);
                console.log(`[DEBUG] Deleted record with sha256sum: ${sha256sum} and lang: ${lang}`);
            }
        };
        request.onerror = (error) => {
            console.error('[ERROR] Error deleting record ===> ', error);
        };
    }
    getLatestRecordForSha256(sha256sum) {
        let transaction = this.db.transaction(['translation'], 'readonly');
        let objectStore = transaction.objectStore('translation');
        let index = objectStore.index('sha256sum');
        let request = index.openCursor(IDBKeyRange.only(sha256sum), 'prev');
        return new Promise((resolve, reject) => {
            request.onsuccess = (e) => {
                let cursor = e.target.result;
                if (cursor) {
                    resolve(cursor.value);
                }
                else {
                    console.log('[DEBUG] No records found for sha256sum ===> ', sha256sum);
                    resolve(null);
                }
            };
            request.onerror = (error) => {
                console.error('[ERROR] Error retrieving latest record for sha256sum ===> ', error);
                reject(error);
            };
        });
    }
    getLatestTranslationRecord(lang) {
        let transaction = this.db.transaction(['translation'], 'readonly');
        let objectStore = transaction.objectStore('translation');
        let index = objectStore.index('lang');
        let request = index.openCursor(IDBKeyRange.only(lang), 'prev');
        return new Promise((resolve, reject) => {
            request.onsuccess = (e) => {
                let cursor = e.target.result;
                if (cursor) {
                    resolve(cursor.value);
                }
                else {
                    console.log('[DEBUG] No records found for lang:', lang);
                    resolve(null);
                }
            };
            request.onerror = (error) => {
                console.error('[ERROR] Error retrieving latest record ===> ', error);
                reject(error);
            };
        });
    }
    registerURLObserver() {
        this.currentUrl = window.location.href;
        window.addEventListener('popstate', this.handleURLChange.bind(this));
        window.addEventListener('hashchange', this.handleURLChange.bind(this));
    }
    handleURLChange() {
        if (window.location.href !== this.currentUrl) {
            console.log('[DEBUG] URL changed ===> ', this.currentUrl, window.location.href);
            this.currentUrl = window.location.href;
            this.translate();
        }
    }
    getAvailableTranslations() {
        const sha256sum = this.localTranslations ? this.localTranslations.sha256sum : null;
        return new Promise((resolve, reject) => {
            this.httpClient(`${this.apiUrl}/get-available-translations`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ lang: this.lang, sha256sum })
            }).then(async (response) => {
                if ((response.sha256sum && response.translations) && sha256sum !== response.sha256sum) {
                    this.insertTranslationRecord(response.sha256sum, JSON.stringify(response.translations), this.lang);
                    this.availableTranslations = response.translations;
                    if (sha256sum) {
                        this.deleteRecordBySha256AndLang(sha256sum, this.lang);
                    }
                }
                resolve(this.availableTranslations);
            }).catch((error) => {
                reject(error);
            });
        });
    }
    translate() {
        if (!Object.keys(this.availableTranslations).length)
            return;
        let allTextNodes = this.getTextNodes(this.rootElement, this.ignoredTags);
        let texts = {};
        const pageUrl = window.location.href;
        allTextNodes.forEach(node => {
            const text = node.textNode.textContent.trim();
            const key = `${node.parentTag}-${node.parentId}-${node.parentClass}-text-${text}`;
            if (text) {
                texts[key] = {
                    key: `${node.parentTag}-${node.parentId}-${node.parentClass}-text-${text}`,
                    text: text,
                    pageUrl
                };
            }
        });
        if (Object.keys(texts).length) {
            const result = this.findMatchingAndMissingKeysWithValues(texts, this.availableTranslations);
            if (Object.keys(result.translationFound).length) {
                this.replaceTextWithTranslation(result.translationFound);
            }
            if (Object.keys(result.translationMissing).length) {
                this.sendForTranslationBulk(result.translationMissing);
            }
        }
    }
    async restart(lang) {
        this.lang = lang || 'en';
        await this.getAvailableTranslations();
        this.translate();
    }
    sendForTranslationBulk(texts) {
        this.httpClient(`${this.apiUrl}/translate-text-bulk`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ texts })
        })
            .then((response) => { })
            .catch((error) => { });
    }
    findMatchingAndMissingKeysWithValues(foundText, availableTranslation) {
        const foundTextKeys = Object.keys(foundText);
        const availableTranslationKeys = Object.keys(availableTranslation);
        const translationFound = foundTextKeys.filter(key => availableTranslationKeys.includes(key)).reduce((acc, key) => {
            acc[key] = { text: foundText[key], translation: availableTranslation[key] };
            return acc;
        }, {});
        const translationMissing = foundTextKeys.filter(key => !availableTranslationKeys.includes(key)).reduce((acc, key) => {
            acc[key] = foundText[key];
            return acc;
        }, {});
        return {
            translationFound,
            translationMissing
        };
    }
    defaultHttpClient(url, options = {}) {
        return fetch(url, options)
            .then(response => {
            if (!response.ok)
                throw new Error(`HTTP error! Status: ${response.status}`);
            return response.json();
        })
            .catch(error => {
            console.error('HTTP request failed', error);
            throw error;
        });
    }
    escapeSelector(selector) {
        return selector.replace(/([^\w-])/g, '\\$1');
    }
    replaceTextWithTranslation(translationFound) {
        Object.keys(translationFound).forEach(matchedKey => {
            const { key, text, translation: translatedText } = translationFound[matchedKey].translation;
            const escapedKey = this.escapeSelector(key);
            const parentNodes = this.rootElement.querySelectorAll(`[data-key*="${escapedKey}"]`);
            parentNodes.forEach(parentNode => {
                parentNode.setAttribute('trinka-translated', 'true');
                parentNode.childNodes.forEach(childNode => {
                    var _a;
                    if (childNode.nodeType === Node.TEXT_NODE) {
                        const originalText = (_a = childNode.textContent) === null || _a === void 0 ? void 0 : _a.trim();
                        if (originalText && originalText === text) {
                            childNode.textContent = translatedText || text;
                        }
                    }
                });
            });
        });
    }
    registerDOMObserver() {
        const debounce_leading = (func, timeout = 5000) => {
            let lastExecutedTime = 0;
            let firstExecution = true;
            return (...args) => {
                const now = Date.now();
                if (firstExecution || now - lastExecutedTime >= timeout) {
                    firstExecution = false;
                    lastExecutedTime = now;
                    func.apply(this, args);
                }
            };
        };
        const debounce_with_threshold = (func, threshold = 5, windowTime = 2000, cooldownTime = 20000) => {
            let callCount = 0;
            let lastExecutedTime = 0;
            let lastCallTime = 0;
            let cooldown = false;
            return (...args) => {
                const now = Date.now();
                if (now - lastCallTime > windowTime) {
                    callCount = 1;
                }
                else {
                    callCount++;
                }
                lastCallTime = now;
                if (callCount >= threshold && !cooldown) {
                    cooldown = true;
                    console.log(`[DEBUG] Cooldown activated: No further calls for ${cooldownTime} seconds.`);
                    observer.disconnect();
                    setTimeout(() => {
                        cooldown = false;
                        console.log('[DEBUG] Cooldown finished. Ready to execute again.');
                        observer.observe(document.body, config);
                    }, cooldownTime);
                }
                if (!cooldown) {
                    func.apply(this, args);
                    lastExecutedTime = now;
                }
            };
        };
        const onBodyChange = debounce_with_threshold((mutations) => {
            console.log('[DEBUG] Something has changed in the DOM');
            observer.disconnect();
            this.translate();
            observer.observe(document.body, config);
        }, 10, 3000, (1000 * 60));
        const observer = new MutationObserver(onBodyChange);
        const config = { childList: true, subtree: true, attributes: false };
        observer.observe(document.body, config);
    }
    filterTextNodes(str) {
        return !this.isNumber(str) &&
            !this.isValidDate(str) &&
            !this.hasMetrics(str) &&
            !this.isCurrency(str) &&
            !this.isEmail(str) &&
            !this.isRange(str) &&
            !this.isOfPattern(str) &&
            !this.isSymbol(str) &&
            !this.isNumberWithFullStop(str) &&
            !this.isNumberWithColon(str) &&
            !this.isNumberWithWords(str);
    }
    isNumber(str) {
        const numberWithCommaPattern = /^\d{1,3}(?:,\d{3})*$/;
        const numberWithSpacePattern = /^\d+\s+\d+$/;
        return !isNaN(Number(str)) && str.trim() !== '' &&
            !numberWithCommaPattern.test(str) &&
            !numberWithSpacePattern.test(str);
    }
    isValidDate(str) {
        const regexes = [
            /^\d{4}-\d{2}-\d{2}$/,
            /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/,
            /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
            /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/,
            /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4}$/,
            /^(0[1-9]|[12][0-9]|3[01])\/[A-Za-z]{3}\/\d{2}$/,
            /^(0[1-9]|[12][0-9]|3[01])\/[A-Za-z]{3}\/\d{2} \d{2}:\d{2}$/,
            /^(0[1-9]|[12][0-9]|3[01])\/[A-Za-z]{3}\/\d{4}$/,
            /^(0[1-9]|[12][0-9]|3[01])\/[A-Za-z]{3}\/\d{4},\s\d{1,2}:\d{2}\s[APap][Mm]$/,
            /^(January|February|March|April|May|June|July|August|September|October|November|December)\s(0[1-9]|[12][0-9]|3[01]),\s\d{4}$/
        ];
        return regexes.some(regex => regex.test(str));
    }
    hasMetrics(str) {
        const metricPattern = /^\d+\s*(%|km|MB|Gb|KB|m|cm|kg|lb|sec|min|hr|day|week|month|year|th|st|nd|rd)$/i;
        return metricPattern.test(str);
    }
    isCurrency(str) {
        const currencyPattern = /^[\$\¥\₹\₩]\d+(?:,\d{3})*(?:\.\d+)?$/;
        return currencyPattern.test(str);
    }
    isEmail(str) {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(str);
    }
    isRange(str) {
        const rangePattern = /^\d+-\d+$/;
        return rangePattern.test(str);
    }
    isOfPattern(str) {
        const ofPattern = /^\d+\s?of\s?\d+$/;
        return ofPattern.test(str);
    }
    isSymbol(str) {
        const symbolPattern = /(^|\s)([!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~])($|\s)/;
        return symbolPattern.test(str) && !/[\s\S]{2,}/.test(str.trim());
    }
    isNumberWithFullStop(str) {
        const numberWithFullStopPattern = /^\d+\.$/;
        return numberWithFullStopPattern.test(str);
    }
    isNumberWithColon(str) {
        const numberWithColonPattern = /^\d+\s?:\s?\d+$/;
        return numberWithColonPattern.test(str);
    }
    isNumberWithWords(str) {
        const numberWithWordsPattern = /^\d+\s+words$/i;
        return numberWithWordsPattern.test(str);
    }
    getTextNodes(node, ignoredTags) {
        var _a, _b;
        let textNodes = [];
        if (node.nodeType === Node.TEXT_NODE && ((_a = node.textContent) === null || _a === void 0 ? void 0 : _a.trim()) && this.filterTextNodes((_b = node.textContent) === null || _b === void 0 ? void 0 : _b.trim())) {
            const text = node.textContent.trim();
            const parentNode = node.parentNode;
            const parentTag = parentNode.tagName.toLowerCase();
            const parentId = parentNode.id || null;
            const parentClass = parentNode.className || null;
            const key = `${parentTag}-${parentId}-${parentClass}-text-${text}`;
            this.addDataKeyToParent(parentNode, key);
            textNodes.push({
                textNode: node,
                parentTag,
                parentId,
                parentClass,
                key,
            });
        }
        else if (node instanceof HTMLElement && node.nodeType === Node.ELEMENT_NODE) {
            if (!ignoredTags.includes(node.tagName.toLowerCase()) && !node.hasAttribute('data-no-translate') && !node.hasAttribute('trinka-translated')) {
                for (let child of node.childNodes) {
                    textNodes.push(...this.getTextNodes(child, ignoredTags));
                }
            }
        }
        return textNodes;
    }
    addDataKeyToParent(parentNode, key) {
        const separator = "~|~";
        if (parentNode.hasAttribute('data-key')) {
            let existingKeys = parentNode.getAttribute('data-key');
            if (!(existingKeys === null || existingKeys === void 0 ? void 0 : existingKeys.split(separator).includes(key))) {
                existingKeys += `${separator}${key}`;
                setTimeout(() => {
                    if (!existingKeys)
                        return;
                    parentNode.setAttribute('data-key', existingKeys);
                }, 0);
            }
        }
        else {
            parentNode.setAttribute('data-key', key);
        }
    }
}
exports.TranslationSDK = TranslationSDK;
window.TranslationSDK = TranslationSDK;
